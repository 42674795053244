import React, { useContext } from 'react';
import * as Ink from '@carta/ink';
import { createUrl } from 'playroom';
import moment from 'moment';
import { LiveProvider, LivePreview, LiveEditor } from 'react-live';
import * as falso from '@ngneat/falso';
import styled, { css } from 'styled-components';
import { useAnalyticsV2 } from '@carta/fep-analytics';

import DocsContext from '../../contexts/DocsContext';
import { makeSlug, formatCode, getEditorStyle } from '../../helpers';
import { codeStyles, editorStyles } from '../../styles/code';
import StyledLink from '../Common/StyledLink';

import { MDRendererWithMapping } from '../Common/MarkdownRenderers';
import CopyUrlWrapper from '../Common/CopyUrlWrapper';

/**
 * Wraps a code sample with <FunctionWrapper> if it is a function.
 * It also optionally strips a trailing semicolon if it exists.
 *
 * () => { };  ==>  <FunctionWrapper>() => { }</FunctionWrapper>
 * () => { }   ==>  <FunctionWrapper>() => { }</FunctionWrapper>
 * <Button />  ==>  <Button />
 * @param {string} sample Code sample
 */
function functionWrapperWrap(sample: string) {
  return sample.trim().replace(/(.*)};?$/gs, '<FunctionWrapper>\n  {$1}}\n</FunctionWrapper>');
}

// Changing editor padding is counter intuitive. Details here:
// https://github.com/carta/ink/pull/3827
const { padding, ...safeStyles } = editorStyles; // Use `LiveEditor` dedicated padding prop instead

const StyledLiveEditor = props => (
  <LiveEditor
    {...props}
    padding={editorStyles.padding} // must use this prop; react-simple-code-editor syncs <pre> with underlying <textarea>
    style={{
      ...codeStyles,
      ...safeStyles,
    }}
  />
);

const PreviewContainer = styled.div`
  padding: 32px 16px;
  background: white;
  border: 1px solid ${Ink.tokens.color.global.brand['gray-20']};
  ${({ theme }) =>
    theme.transparentBackground &&
    css`
      background-image: linear-gradient(45deg, rgb(249, 249, 250) 25%, transparent 25%),
        linear-gradient(135deg, rgb(249, 249, 250) 25%, transparent 25%),
        linear-gradient(45deg, transparent 75%, rgb(249, 249, 250) 75%),
        linear-gradient(135deg, transparent 75%, rgb(249, 249, 250) 75%);
      background-size: 20px 20px;
      background-position: 0px 0px, 10px 0px, 10px -10px, 0px 10px;
    `};
`;

const StickyWrapper = styled.div`
  background: #fff;
  z-index: 998; // modal is 1000, this has to be under it

  ${({ theme }) =>
    theme.sticky &&
    css`
      position: sticky;
      top: 0;
      box-shadow: 0 1px 0 0 ${Ink.tokens.color.global.brand['gray-20']};
    `};
`;

type Props = {
  previewOnly?: boolean;
  codeOnly?: boolean;
  codeOpen?: boolean;
  sample: {
    name: string;
    description: string;
    code: string;
    environments: string[];
    solidBackground?: boolean;
  };
};

falso.seed('ink');

const scope = { ...Ink, moment, React, StyledLink, falso };

const SampleViewer = ({ previewOnly = false, codeOnly = false, codeOpen = false, sample }: Props) => {
  const { trackAction } = useAnalyticsV2();
  const { defaultShowsCode, editorStyle } = useContext(DocsContext);
  const { isMobile, isTabletPortrait } = Ink.useWindowWidth();

  const [open, setOpen] = React.useState(codeOpen || defaultShowsCode);
  const [transparentBackground, setTransparentBackground] = React.useState(!sample.solidBackground);
  const [liveCode, setLiveCode] = React.useState<string>(sample.code);

  const copyCodeToClipboard = () => {
    if (sample) navigator.clipboard.writeText(liveCode).then();
    trackAction({
      elementId: 'copy-code-to-clipboard',
      type: 'click',
    });
  };

  const toggleTransparentBackground = () => {
    setTransparentBackground(!transparentBackground);
    trackAction({
      elementId: 'toggle-transparent-background',
      type: 'click',
    });
  };

  const toggleCode = () => {
    setOpen(!open);
    trackAction({
      elementId: 'toggle-code',
      type: 'click',
    });
  };

  const playroomLinkProps = {
    href: `https://ink.carta.com/playroom/${createUrl({
      code: functionWrapperWrap(liveCode),
    })}`,
    onClick: () => {
      trackAction({
        elementId: 'move-to-playroom',
        type: 'click',
      });
    },
  };

  return (
    <Ink.Box top="large" id={sample ? makeSlug(sample.name) : undefined}>
      <LiveProvider code={open ? formatCode(liveCode) : liveCode} scope={scope} theme={getEditorStyle(editorStyle)}>
        <StickyWrapper theme={{ sticky: open }}>
          {/* Sample title, description, and copy link, on the left hand side, stay the same on all screen widths.
              But how we layout the options for each sample, on the right hand side, does change.
              Separate from responsiveness considerations, figuring out spacing between sample header and preview
              section below it took a while. Most important to notice is:
              1. Title and description vary a lot across samples, and may wrap multiple lines. Default “baseline”
              alignment of the HStack below helps a lot.
              2. We cannot set bottom spacing on the whole header, because it would compound on top of the spacing
              that title and description already have. So the trick is to add bottom spacing to the right hand side
              wrapper of sample options instead. That yields best results. */}

          <Ink.HStack align="distributed" spacing={['medium', 'medium', 'gutter']}>
            {!previewOnly && sample && (
              <Ink.Box>
                <CopyUrlWrapper hash={makeSlug(sample.name)}>
                  <Ink.Heading variant="heading-3" as="span">
                    {sample.name}
                  </Ink.Heading>
                </CopyUrlWrapper>
                {sample.description && <MDRendererWithMapping md={sample.description} />}
              </Ink.Box>
            )}

            {/* This bottom spacing yields best results across different scenarios: */}
            <Ink.Box bottom="medium">
              {isMobile || isTabletPortrait ? (
                // On mobile, all sample options are always present inside the dropdown,
                // unlike on desktop, where we display certain options in certain conditions
                <Ink.Dropdown
                  align="right"
                  trigger={props => <Ink.Dropdown.Trigger {...props}>Code</Ink.Dropdown.Trigger>}
                >
                  {!codeOnly && (
                    <Ink.Dropdown.Item
                      render={() => (
                        <Ink.Dropdown.Button onClick={toggleCode}>
                          {open ? 'Hide code' : 'View code'}
                        </Ink.Dropdown.Button>
                      )}
                    />
                  )}
                  <Ink.Dropdown.Item
                    render={() => <Ink.Dropdown.Button onClick={copyCodeToClipboard}>Copy code</Ink.Dropdown.Button>}
                  />
                  <Ink.Dropdown.Item
                    render={() => (
                      <Ink.Dropdown.Anchor target="_blank" rel="noopener noreferrer" {...playroomLinkProps}>
                        Move code to Playroom
                      </Ink.Dropdown.Anchor>
                    )}
                  />
                  <Ink.Dropdown.Separator />
                  <Ink.Dropdown.Item
                    render={() => (
                      <Ink.Dropdown.Button
                        id={`with-transparent-background-${sample.name.replace(/\s/g, '-')}`}
                        onClick={toggleTransparentBackground}
                      >
                        {transparentBackground ? 'Show solid background' : 'Show transparent background'}
                      </Ink.Dropdown.Button>
                    )}
                  />
                </Ink.Dropdown>
              ) : (
                <Ink.HStack align="right" spacing="medium">
                  {open && (
                    <>
                      <Ink.Button size="small" onClick={toggleTransparentBackground}>
                        {transparentBackground ? 'Show solid background' : 'Show transparent background'}
                      </Ink.Button>
                      <Ink.Button size="small" onClick={copyCodeToClipboard}>
                        Copy code
                      </Ink.Button>
                    </>
                  )}
                  {!codeOnly && (
                    <Ink.Button size="small" onClick={toggleCode}>
                      {open ? 'Hide code' : 'View code'}
                    </Ink.Button>
                  )}
                  <Ink.Anchor
                    size="small"
                    type="btn"
                    target="_blank"
                    rel="noopener noreferrer"
                    preset="new-tab"
                    {...playroomLinkProps}
                  >
                    Move to Playroom
                  </Ink.Anchor>
                </Ink.HStack>
              )}
            </Ink.Box>
          </Ink.HStack>
        </StickyWrapper>
        {!codeOnly && (
          <PreviewContainer theme={{ transparentBackground }}>
            <LivePreview />
          </PreviewContainer>
        )}
        {(open || codeOnly) && <StyledLiveEditor onChange={setLiveCode} />}
      </LiveProvider>
    </Ink.Box>
  );
};

export default SampleViewer;
